export interface FontStyles {
  fontThin: string;
  fontRegular: string;
  fontMedium: string;
  fontBold: string;
  fontExtraBold: string;
  fontHandWritten: string;
}

export function usingFonts() {
  var fontThin: string;
  var fontRegular;
  var fontMedium;
  var fontBold;
  var fontExtraBold;
  var fontHandWritten;

  var typeFont: any = [];
  var typeFontReturn = {} as FontStyles;

  typeFontReturn = {
    fontThin: "MSPLUSRounded1c-Thin",
    fontRegular: "MSPLUSRounded1c-Regular",
    fontMedium: "MSPLUSRounded1c-Medium",
    fontBold: "MSPLUSRounded1c-Bold",
    fontExtraBold: "MSPLUSRounded1c-ExtraBold",
    fontHandWritten: "Montserrat-Regular",
  };
  typeFont = [
    fontThin = typeFontReturn.fontThin,
    fontRegular = typeFontReturn.fontRegular,
    fontMedium = typeFontReturn.fontMedium,
    fontBold = typeFontReturn.fontBold,
    fontExtraBold = typeFontReturn.fontExtraBold,
    fontHandWritten = typeFontReturn.fontHandWritten,
  ];

  const fonts = typeFont.join();

  return fonts;
}
