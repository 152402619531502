import "../src/index.css";
import './App.css';
import { Box, Button, createTheme, Typography } from '@mui/material';

import { usingFonts } from './constants/usingFonts';


function App() {
  const fonts = usingFonts();


  const mainTheme = createTheme({
    typography: {
      fontFamily: fonts,
    },
  });

  return (
    <>
      <Box display="flex" flexDirection="row" bgcolor="#0582ff" height="5vh" justifyContent="center" alignItems="center">
        <Typography fontSize={32} fontFamily="Montserrat-Regular" color="white">Platance</Typography>
      </Box>
      <Box margin={4}>
        <Typography fontSize={32} fontFamily="Montserrat-Regular">Leading development in web stores</Typography>
        <Typography fontSize={18} fontFamily="Montserrat-Regular">We set focus on delivering enterprise solutions to customers in all sizes</Typography>
      </Box>
      <Box margin={4} display="flex" flexDirection="column">
        <Typography fontSize={18} fontFamily="Montserrat-Regular">Get started</Typography>
      </Box>
      <Box margin={4} display="flex" flexDirection="column">
        <Typography fontSize={18} fontFamily="Montserrat-Regular">Learn more about our processes</Typography>
        <Typography fontSize={18} fontFamily="Montserrat-Regular">We take our commitments to the to the next level to deliver ease of mind to our customers. In developing these platforms alot of thinking goes into it. That is why we set criterias to when features or adjustments have reached enterprise quality.</Typography>
      </Box>
      <Box margin={4} display="flex" flexDirection="column">
        <Typography fontSize={18} fontFamily="Montserrat-Regular">See our platform</Typography>
        <Typography fontSize={18} fontFamily="Montserrat-Regular" mt={2}>Choose the right platform for your needs. Greatness shop single edition offers single store where you can manage your store. It is designed to get you started or enhance your potensial to sell more. We help you to choose the right scale for your business to get you started.</Typography>
      </Box>
      <Box margin={4} display="flex" flexDirection="row">
        <Box
          sx={{
            background: 'linear-gradient(45deg, #0582ff 60%, #ffffff 30%)',
            borderRadius: 1,
            height: '1.2vh', // Example height, adjust as needed
            width: '1.3vw',   // Example width, adjust as needed
          }} />
        <Typography fontSize={18} fontFamily="Montserrat-Regular" ml={2}>See whats coming soon</Typography>
        <Box
          sx={{
            background: 'linear-gradient(45deg, #0582ff 60%, #ffffff 30%)',
            borderRadius: '50%',
            height: '1.2vh', // Example size, adjust as needed
            width: '1.3vw',  // Example size, adjust as needed
          }}
        />
        <Typography fontSize={18} fontFamily="Montserrat-Regular" ml={2}>Contact us</Typography>
      </Box>
      {/* <Box display="flex" flexDirection="row" bgcolor="#0582ff" height="5vh" justifyContent="center" alignItems="center">
        <Button variant="contained" color="primary">Login</Button>
        <Button variant="contained" color="primary">Register</Button>
      </Box> */}
      {/* <QuickLinks /> */}
    </>

  );
}

export default App;
